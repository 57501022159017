import React, { Component } from 'react'
import Head from '../head/'
import { Button, Icon, notification } from 'antd'
import businessQuitTime from '../../utils/businessQuitTime'
import { 
  printRechargeBill,
  printDepositBill,
  printRegBill,
  printCardFee,
  printPayCopy,
  printPayBillHis,
  sendExitCard,
  applyEinvoice,
  printEinvNotice,
  getPrintregdata,
 } from '@/utils/request'
import { connect } from 'react-redux'
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as rechargeAction from '../../store/actions/recharge'
import './print.scss'

const btnStyle = {
  backgroundColor: "#00bd58",
  border: "1px #00bd58 solid",
  marginTop: "20px",
  marginLeft: "20px",
}
let seccessNum = 0
// 3秒退出
const backTime = 3

/*
* 打印
*/
class RechargePrint extends Component {
  constructor(props){
    super(props)
    this.state = {
      printContext: '正在打印中请等待...',
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentDidMount(){
    this.selectPrint()
  }

  selectPrint(){
    const { payment } = this.props

    switch(payment.businessType){
      case "inpatient":
        this.sendPrintDepositBill();
        break;

      case "recharge":
        this.sendPrintCardRecharge();
        break;

      case "takeNo":
        this.queryRegPrintData();
        break;

      case "createCard":
        this.sendPrintCardFee();
        break;

      case "reprint":
        this.sendReprint();
        break;

      case "payBill":
        this.sendPrintPayBill();
        break;

      default: break;
    }
  }

  // 就诊卡充值打印
  sendPrintCardRecharge(){
    const that = this
    const { cardInfo, payment } = this.props
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    const params = {
      HOS_NAME: base.hospitalInfo.value,
      CZ_SERIAL: payment.orderId,
      CZ_TYPE: payment.payType,
      COST: payment.amount.toString(),
      DISPLAY_NO: cardInfo.display_no,
      P_NAME: cardInfo.userInfo.NAME,
      BALANCE: cardInfo.balance,
      OP_CODE: `自助${deviceNum}`
    }

    printRechargeBill(params)
    .then(od => {
      if(od.status){
        that.setState({
          printContext: od.message
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 住院押金打印
  sendPrintDepositBill(){
    const that = this
    const { payment, inpatient } = this.props
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    const params = {
      HOS_NAME: base.hospitalInfo.value,
      CZ_SERIAL: payment.orderId,
      CZ_TYPE: payment.payType,
      COST: payment.amount.toString(),
      IN_NO: inpatient.inpatientNo,
      P_NAME: inpatient.patientInfo.NAME,
      BALANCE: inpatient.balance,
      OP_CODE: `自助${deviceNum}`
    }

    printDepositBill(params)
    .then(od => {
      if(od.status){
        that.setState({
          printContext: od.message
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取挂号数据
  queryRegPrintData() {
    const that = this
    const { payment } = this.props
    const takeNoData = payment.takeNo
    const params = {
      CL_NO: takeNoData.CL_NO
    }
    getPrintregdata(params)
    .then(od => {
      if(od.status){
        that.sendPrintRegBill(od.data)
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 挂号打印
  sendPrintRegBill(params){
    const that = this
    // const { payment, cardInfo } = this.props
    // const base = JSON.parse(localStorage.getItem('base'))
    // const { deviceId } = base;
    // const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    // const takeNoData = payment.takeNo
    // const params = {
    //   HOS_NAME: takeNoData.HOS_NAME,    // 医院名称
    //   DISPLAY_NO: cardInfo.display_no,  // 就诊卡号
    //   P_NAME: takeNoData.P_NAME,        // 患者姓名
    //   SE_NAME: takeNoData.SE_NAME,      // 预约科室
    //   RE_DATE: takeNoData.RE_DATE,      // 预约日期
    //   RE_TIME: takeNoData.RE_TIME,      // 就诊时段
    //   RE_TYPE: takeNoData.REG_TYPE,     // 挂号类别
    //   REG_FEE: takeNoData.REG_FEE,      // 挂号费
    //   DIA_FEE: takeNoData.DIA_FEE,      // 诊察费
    //   REG_CODE: takeNoData.REG_CODE,    // 挂号单号
    //   NUMBER: takeNoData.NUMBER,        // 序号
    //   DOC_NAME: takeNoData.DOC_NAME || '',  // 医生名称
    //   OP_CODE: `自助${deviceNum}`,       // 操作员
    //   MR_NO: cardInfo.mr_no,            // 患者id
    //   CL_NO: takeNoData.CL_NO,          // 就诊号
    // }
    
    printRegBill(params)
    .then(od => {
      if(od.status){
        that.setState({
          printContext: od.message,
          quitTime: backTime,
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 卡费支付
  sendPrintCardFee(){
    const that = this
    const { payment, createCard } = this.props
    const { NAME, NUMBER } = createCard.userInfo
    const base = JSON.parse(localStorage.getItem('base'))
    const { deviceId } = base
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId
    const BALANCE = parseFloat(payment.amount - payment.cardFee).toString()
    let params = {}

    if(payment.cardFee == 0){
      params = {
        HOS_NAME: base.hospitalInfo.value,
        CZ_SERIAL: payment.orderId,
        CZ_TYPE: payment.payType,
        COST: payment.amount.toString(),
        DISPLAY_NO: createCard.displayNo,
        P_NAME: NAME,
        BALANCE,
        OP_CODE: `自助${deviceNum}`
      }

      printRechargeBill(params)
      .then(od => {
        if(od.status){
          that.setState({
            printContext: od.message
          })
          that.exitCard()
        }
      })
      .catch(err => {
        that.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    }
    else{
      params = {
        HOS_NAME: base.hospitalInfo.value,
        P_NAME: NAME,
        CZ_SERIAL: payment.orderId,
        CERTI_NO: NUMBER,
        CZ_TYPE: payment.payType,
        COST: payment.amount.toString(),
        OP_CODE: `自助${deviceNum}`,
        REMARKS: '',
      }

      printCardFee(params)
      .then(od => {
        that.props.history.push({ 
          pathname : '/grantCard'
        })   
      })
      .catch(err => {
        that.props.history.push({ 
          pathname : '/grantCard'
        }) 
      })
    }
  }

  // 手动退出新建卡
  exitCard(){
    const that = this

    sendExitCard({
      RESOURSE_ID: 'jzcard'
    })
    .then(od => {
      if(od.status){
        that.props.history.push({ 
          pathname : '/success',
          query : { messageTitle: '建档成功', messageContent: '请取出就诊卡妥善保管'}
        })
      }
      else{
        notification.error({
          message: `错误`,
          description: od.message,
          duration: 10,
        })
      }
    })
    .catch(err => {
      that.isSignOutBtn = false
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 补打
  sendReprint(){
    const that = this
    const { admin } = this.props
    const { reprint } = admin
    const {
      deviceId,
      fee,
      modifiedAt,
      orderId,
      platform,
      subject,
      transactionId,
     } = reprint

    const params = {
      platform,
      orderId,
      subject,
      fee,
      deviceId,
      transactionId,
      modifiedAt,
    }

    printPayCopy(params)
    .then(od => {
      if(od.status){
        that.setState({
          printContext: od.message
        })
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 打印门诊缴费凭条
  sendPrintPayBill(){
    const that = this
    const { payment, cardInfo, base, outpatient } = this.props
    const { userInfo, display_no } = cardInfo
    const { deviceId, hospitalInfo } = base
    const { recordDetail } = outpatient
    const isInvoice = hospitalInfo.isInvoice
    const deviceNum = (Number(deviceId) < 10) ? `0${deviceId}` : deviceId

    let sendParams = {
      HOS_NAME: hospitalInfo.value,
      P_NAME: userInfo.NAME,
      COST: payment.amount,
      // BALANCE: cardInfo.balance,
      OP_CODE: `自助${deviceNum}`,
      DETAILS: []
    }

    if(cardInfo.currentCard.id === 'jzcard'){
      sendParams['DISPLAY_NO'] = display_no
    }

    recordDetail.forEach((detail) => {
      sendParams.DETAILS.push({
        ITEM_NAME: detail.M_NAME,
        ITEM_NUM: detail.M_COUNT,
        ITEM_PRICE: detail.M_PRICE,
        ITEM_COST: detail.M_COST,
        REQ_NO: detail.REQ_NO,
        EXEC_SE_NAME: detail.SE_NAME,
        INV_NO: detail.INV_NO
      })
    })

    printPayBillHis(sendParams)
    .then(od => {
      if(od.status){
        that.setState({
          printContext: od.message
        })

        // 不使用医保结算的医院，走先申请发票，在打印电子发票
        if(!base.isMedicalSettle && isInvoice) {
          this.sendApplyEinvoice()
        }
        // 使用医保结算的医院，直接打印电子发票
        if(base.isMedicalSettle 
            && isInvoice 
              && payment.einvInfo 
                && payment.einvInfo.length > 0) {
          this.sendSettleEinvoice()
        }
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 向his申请电子发票
  sendApplyEinvoice(){
    const { outpatient } = this.props
    const { recordDetail } = outpatient
    recordDetail.forEach((detail) => {
      applyEinvoice({
        "处方号": detail.RE_NO
      })
      .then(od => {
        if(od.status){
          this.sendPrintEinvNotice(od.data)
        }
        else{
          this.setState({
            printContext: od.message
          })
        }
      })
      .catch(err => {
        this.props.history.push({ 
          pathname : '/error',
          query : { messageTitle: '失败', messageContent: err.toString()}
        })
      })
    })
  }

  // 通过InsuSettle医保结算，返回的einv_info来循环打印
  sendSettleEinvoice(){
    const { payment } = this.props

    if(payment.einvInfo && payment.einvInfo.length > 0){
      payment.einvInfo.map((einvs) => {
        const { hos_name='', charge_date='', einvoicecode='', einvoicenumber='', randomnumber='', p_name='', cost='', ip_check='' } = einvs
        const transEinvs = {
          医院名称: hos_name,
          开票时间: charge_date,
          票据代码: einvoicecode,
          票据号码: einvoicenumber,
          校验码: randomnumber,
          交款人: p_name,
          合计金额: cost,
          票据查验地址: ip_check,
        }

        this.sendPrintEinvNotice(transEinvs)
      })
    }
  }
  
  // 打印电子发票
  sendPrintEinvNotice(params){
    const { base } = this.props
    const { hospitalInfo } = base
    let sendParams = params
    sendParams['医院名称'] = hospitalInfo.value
    printEinvNotice(sendParams)
    .then(od => {
      if(od.message){
        this.setState({
          printContext: od.message
        })
      }
    })
    .catch(err => {
      this.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  goPaymentList(){
    this.props.history.push('/payMentList')
  }

  render() {
    const { printContext } = this.state
    const { payment } = this.props

    return (
      <div>
        <Head quitTime={businessQuitTime.print.payPrint} />

        <div className="context-container clearfix">
          <div className="status-box">
            <div className="print-ico">
              <i className="icon iconfont icon--tiaomadayin"></i>
            </div>
            <div className="print-text">
              <h1>{ printContext }</h1>
            </div>
          </div>
          {
            (payment && payment.businessType === 'payBill' && payment.printTitle === '门诊缴费') ?
            (<div className="payment-btn">
              <Button type="primary" size="large" onClick={() => this.goPaymentList()} style={btnStyle}>继续门诊缴费</Button>
            </div>) : ''
          }
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { cardInfo,
          recharge,
          base,
          payment,
          inpatient,
          outpatient,
          createCard,
          admin } = state

  return {
    cardInfo,
    recharge,
    base,
    payment,
    inpatient,
    outpatient,
    createCard,
    admin
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data))
    },
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RechargePrint)