import React, { Component } from 'react'
import Head from '../../components/head'
import { Button, Icon, notification } from 'antd'
import { connect } from 'react-redux'
import {
  getTakeCard,
  getHisPatientInfo,
  getHisBalance,
  getGetKey,
  readjzCard,
  readICCard,
  readIDCard,
  getPatientID,
  hardwareGetPatientID,
  getDepositCost,
  getCheckCard,
  getPersonInfoByQrCode,
  sendExitCard,
  getInsurEcCode,
  getInsurEcCodeApi,
  localGetPersonInfoByQrCode,
} from '@/utils/request'
import {getGenderFromIDCard, getBirthdayFromIDCard} from '@/utils/common'

// import * as ReadApi from './readApi'   // 老医保二维码读取
import * as cardInfoAction from '../../store/actions/cardInfo'
import * as baseAction from '../../store/actions/base'
import * as inpatientAction from '../../store/actions/inpatient'
import * as Action from '@/store/actions/createCard'

import BrushCardImg from '@/assets/img/brushCard.png'
import idcard from '@/assets/img/cards/idcard.png'
import jzcard from '@/assets/img/cards/jzcard.png'
import ejkcard from '@/assets/img/cards/ejkcard.png'
import ybcard from '@/assets/img/cards/ybcard.png'
import ylcard from '@/assets/img/cards/ylcard.png'
import './readCard.scss'

/*
 * 公用组件 - 读取就诊卡信息
 */
let time1 = null
let time2 = null
let loopTime = null

// 电子凭证业务编码
const busCodeEnum = {
  101: '挂号',
  102: '住院建档',
  103: '入院登记',
  104: '缴纳预交金',
  201: '问诊',
  202: '预约检查',
  203: '检查',
  204: '治疗',
  301: '结算',
  302: '取药',
  303: '取报告',
  304: '打印票据和清单',
  305: '病历材料复印',
}

class ReadCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      cardName: '',
      cardId: '',
      currentCard: {},
      setp1: false,
      setp2: false,
      time1Num: 10,
      time2Num: 3,
      errorFreq: 0,      // 失败频率
      scanCodeMode: [2,3,4,5],     // resourcesMode 2、3、5 都是扫码，4医保二维码本地服务自带弹层
      showInputCard: [2,3,4,5],
      isOut: false,
      baseCode: '',       // 存储是什么功能的电子凭证业务编码
    }

    this.inputRef = React.createRef()
  }

  goHomePage(){
    this.clearTimer(() => {
      this.props.history.push('/')
    })
  }

  /*
    每次都重新读取卡信息，判断卡类型走不同读卡规则
      资源ID       资源名称       Mode
    1 jzcard	    院内就诊卡	    1      1	0
    2 ybcard	    医保卡	       1	    2	0
    3 ylcard	    银联卡	       1	    2	1
    4 jkcard	    居民健康卡	    1      0 0
    5 eybcard     电子医保卡	    2      2 0
    6 ejzcard     院内电子就诊卡   3      0 0
    7 ejkcard     电子健康卡      2      0 0
    8 inputcard   直接录入卡      4      0 0
    9 idcard 	    身份证         1      0 0
    10 weixin	    微信           0	    2	1
    11 alipay	    支付宝	       0	    2	1
    12 YBSFZ      医保二维码      4
    cardList: [
      {
        name: '就诊卡',
        id: 'jzcard',
        resourcesMode: 1,       // 获取用户资源方式：
          0：不支持患者识别，
          1：本地服务(API获取)，
          2：网络获取（扫描二维码 串号）
          3：硬件通过串号获取患者是MR_NO
          4：医保二维码获取患者信息
          5：电子健康卡 读本地接口（两种电子健康卡读取，另一种还是用的2访问后台的服务接口）
        payMode: 0,             // 资源支付：0无支付功能，1内部支付，2外部支付
        isSeparatePay: 0,       // 是否单独支付：0无单独支付，1支持单独支付
      }
    ]
  */

  componentDidMount(){
    console.log('非医保公用读卡~~~')
    this.readCardType()
  }

  componentWillUnmount(){
    clearInterval(time1)
    clearInterval(time2)
    clearTimeout(loopTime)
  }

  // 侦听扫码枪扫码
  bindKeyBoard(evt){
    const { currentCard={} } = this.state
    const { resourcesMode, id } = currentCard

    if(evt.keyCode === 13 || evt.keyCode === 108 || evt.keyCode === 32){
      const cardNo = evt.target.value
      switch(resourcesMode){
        case 2: 
          this.queryNewworkCardId(cardNo)
          break;

        case 3: 
          this.queryHardwarePatientID(cardNo)
          break;

        case 4:
          this.queryYBPatinfo(cardNo)
          break;

        case 5:
          this.queryJKKPatient(cardNo)
          break;

        default:
          break;
      } 
    }
  }

  readCardType(){
    const that = this
    const { scanCodeMode } = this.state
    const { base } = this.props
    const cardList = base.cardList
    const { params } = this.props.match
    const cardId = params.cardId
    const busCode = params.busCode || ''
    
    const currentCard = cardList.find((card) => {
      return card.id === cardId
    })

    this.setState({
      cardName: currentCard.name,
      cardId,
      currentCard,
      busCode,
    }, () => {
      that.showCard()
    })

    if(currentCard.resourcesMode === 1){
      // 启动定时器
      this.startTimer();

      // 判断就诊卡、或其它卡类型走不同硬件接口
      if(currentCard.id === 'jzcard'){
        this.queryTakeCard()
      }
      else if(currentCard.id === 'idcard'){
        this.queryReadIDCard(currentCard.id)
      }
      else {
        this.queryReadICCard(currentCard.id)
      }
    }
    if(scanCodeMode.includes(currentCard.resourcesMode)){
      this.loopInputStatus()

      // if(currentCard.resourcesMode === 4){ // 医保二维码直接调接口不需要等扫码
      //   this.queryYBPatinfo()
      // }
      // else{
      //   setTimeout(function(){
      //     that.inputRef.current.focus()
      //   }, 1000)
      // }
    }
  }

  // 获取input输入框焦点
  loopInputStatus(){
    const that = this
    if(this.inputRef.current){
      that.inputRef.current.focus()
      window.clearTimeout(loopTime)
    }
    else{
      loopTime = window.setTimeout(() => {
        that.loopInputStatus()
      }, 50)
    }
  }

  showCard(){
    const {currentCard} = this.state
    let cardType = ''

    switch(currentCard.id){
      case 'idcard':
        cardType = <img src={idcard} alt=""/>
        break;

      case 'jzcard':
        cardType = <img src={jzcard} alt=""/>
        break;

      case 'ybcard':
        cardType = <img src={ybcard} alt=""/>
        break;

      case 'ejkcard':
        cardType = <img src={ejkcard} alt=""/>
        break;

      case 'ylcard':
        cardType = <img src={ylcard} alt=""/>
        break;

      default:
        cardType = <img src={BrushCardImg} alt=""/>
        break;
    }

    return cardType
  }

  /*
  * 循卡计时逻辑 begin
  * 第一次 进到页面 调用读卡接口自动计时，等待接口响应
  * 第二次 接口响应 false 并RETRY=1，在调用一次接口，并执行第二次10秒计时
  * 第三次 不无需要等待接口响应，如果第二次10秒后直接执行第三次
  */
  // 执行10秒计时
  startTimer() {
    const that = this
    const { errorFreq } = that.state
    console.log('10秒执行', errorFreq)

    clearInterval(time1)
    time1 = setInterval(() => {
      const { time1Num } = that.state
      let time = time1Num - 1

      if(time1Num == 0){
        clearInterval(time1)

        // 如果完成第二次循卡计时，直接展示第三次
        if(errorFreq == 1){
          console.log('调用第三次')
          that.handleCardError()
        }
        that.setState({
          time1Num: 10
        })
      }
      else{
        that.setState({
          time1Num: time
        })
      }
    }, 1000)
  }

  // 处理寻卡失败
  handleCardError(){
    const that = this
    const { errorFreq } = this.state
    let errorFreqNum = errorFreq+1
    let setp1 = true
    let setp2 = false
    console.log('寻卡失败 errorFreq', errorFreqNum)

    if(errorFreqNum === 2){
      setp1 = false
      setp2 = true
    }

    this.setState({
      errorFreq: errorFreqNum,
      setp1,
      setp2,
    }, () => {
      if(errorFreqNum === 0 || errorFreqNum === 1) {
        that.startTimer()
        // that.resetStartTimer()
      }
      else{
        that.startOutTimer()
      }
    })
  }

  // 10秒计时
  resetStartTimer(){
    const that = this
    clearInterval(time1)
    console.log('B resetStartTimer')

    this.setState({
      time1Num: 10
    }, () => {
      that.startTimer()
    })
  }

  // 3秒计时退出
  startOutTimer() {
    const that = this
    clearInterval(time2)
    time2 = setInterval(() => {
      const { time2Num } = that.state
      let time = time2Num - 1
      if(time2Num == 0){
        clearInterval(time2)
        that.signOutCard()
        that.setState({
          time2Num: 3
        })
      }
      else{
        that.setState({
          time2Num: time
        })
      }
    }, 1000)
  }
  /******** 循卡计时逻辑 end ********/

  // 读取就诊卡号
  queryTakeCard(){
    const that = this
    const parentUrl = that.props.match.params.typeName
    const { isOut } = this.state

    if(isOut){
      return ;
    }

    getTakeCard()
    .then(od => {
      if(od.status){
        that.props.addCard({
          cardNo: od.data.CARD_NO
        })

        // 判断来源是否建卡/无卡建卡
        if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){ 
          this.createCardInfo(od.data)
          return ;
        }

        that.checkCard(od.data.CARD_NO)
      }
      else{
        // 如果10秒寻卡超时
        if(od.data['RETRY'] === '1'){
          const { errorFreq } = that.state
          if(errorFreq < 2){
            that.handleCardError()
            that.queryTakeCard()
          }
        }
        else{
          that.signOutCard()
          that.props.clearCard()
        }
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      that.props.clearCard()
    })
  }

  // 查看就诊卡状态
  checkCard(card_no){
    const that = this
    const { currentCard } = this.state

    getCheckCard({
      card_no
    })
    .then(od => {
      if(od.status){

        if(od.data[0].EF_SIGN === '0'){
          notification.error({
            message: `错误`,
            description: '卡已作废',
            duration: 10,
          })
          return false
        }

        if(od.data[0].EF_SIGN === '-1'){
          notification.error({
            message: `错误`,
            description: '卡已挂失',
            duration: 10,
          })
          return false
        }

        if(od.data[0].EF_SIGN === '2'){
          notification.error({
            message: `错误`,
            description: '该卡已做退卡处理，不允许继续使用',
            duration: 10,
          })
          return false
        }

        const { MR_NO, DISPLAY_NO } = od.data[0]
        let mr_no = MR_NO
        let display_no = DISPLAY_NO
        let newCardInfo = Object.assign(that.props.cardInfo, { mr_no, display_no, currentCard })
        that.props.addCard(newCardInfo)
        // 判断卡是否有储值功能，如果有先取一下余额
        if(currentCard.payMode !== 0){
          that.queryBalance()
        }
        else{
          that.queryHisPatientInfo(mr_no)
        }

        // that.queryKeyB(card_no)
      }
      else{
        that.props.clearCard()
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      that.props.clearCard()
    })
  }

  // 本地类型 医保卡、健康卡
  queryReadICCard(cardId){
    const that = this
    const { isOut } = this.state
    console.log('isOut', isOut)

    if(isOut){
      return ;
    }

    readICCard({
      RESOURSE_ID: cardId
    })
    .then(od => {
      if(od.status){
        let cardId = od.data.ID_NO
        const parentUrl = that.props.match.params.typeName

        // response
        // {
        // "ID_NO":"133029195108250018",
        // "CARD_NO":"TF0314274",
        // "PIN_CODE":"131100D15600000500318314CB328A2A",
        // "NAME":"刘彦林",
        // "SIGN_DATE":"20170116",
        // "EXPIR_DATE":"20261222"
        // }
        
        // 判断来源是否建卡/无卡建卡
        if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){ 
          const { ID_NO="", NAME="" } = od.data
          const SEX = ID_NO.substring(16,17) % 2 === 0 ? '女' : '男'
          this.createCardInfo({
            NAME,
            NUMBER: ID_NO,
            BIRTHDAY: ID_NO.substring(6,14),
            SEX,
          })
          return ;
        }

        // 住院回调
        if(parentUrl === 'hospitalizedInfo' || parentUrl === 'printHospitalizedECGReportInfo'){
          that.queryHospitalization(cardId)
        }
        else {  // 门诊处理
          that.queryPatientID({cardId})
        }
      }
      else{
        // 寻卡超时
        if(od.data['RETRY'] === '1'){
          const { errorFreq } = that.state
          if(errorFreq < 2){
            that.handleCardError()
            that.queryReadICCard(cardId)
          }
        }
        else{
          that.signOutCard()
          that.props.clearCard()
        }
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 本地类型 身份证信息获取
  queryReadIDCard(cardId){
    const that = this
    const parentUrl = that.props.match.params.typeName
    const { isOut } = this.state

    if(isOut){
      return ;
    }

    readIDCard()
    .then(od => {
      if(od.status){

        // 测试数据
        // od.data = {
        //   "NAME":"ZHENGJIAN YANGBEN ZHENG JIAN YANG BEN ZHENGJIAN Y",
        //   "SEX":"女",
        //   "BIRTHDAY":"19800101",
        //   "NUMBER":"PAK310080010103",
        //   "ADDRESS":"",
        //   "SIGNDATE":"1500",
        //   "VALIDTERM":"20230808-20330807"
        // }

        const {NUMBER='', ID_TYPE='', CITIZENSHIPCODE='', PEOPLENATIONCODE=''} = od.data
        let cardId = NUMBER;  // 身份证号
        let idType = ID_TYPE  // 身份证类型
        const nationality = CITIZENSHIPCODE;  // 国籍代码
        const folk = PEOPLENATIONCODE;  // 民族代码

        // 判断来源是否建卡/无卡建卡
        if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){
          this.createCardInfo(od.data)
          return ;
        }

        that.queryPatientID({cardId, idType, nationality, folk})
      }
      else{
        // 如果10秒寻卡超时
        if(od.data['RETRY'] === '1'){
          const { errorFreq } = that.state
          if(errorFreq < 2){
            that.handleCardError()
            that.queryReadIDCard(cardId)
          }
        }
        else{
          that.signOutCard()
          that.props.clearCard()
        }
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 通过id获取患者信息mr_no
  queryPatientID(datas){
    const that = this

    getPatientID({
      CERTI_NO: datas.cardId,
    })
    .then(od => {
      if(od.status){
        // 测试数据
        // od.data = [{
        //   "CERTI_NO":"",
        //   "IN_NO":"",
        //   "P_ID":"",
        //   "MR_NO":"H202408280936238",
        //   "AGE":"44\u5468\u5c81",
        //   "NAME":"ZHENGJIAN\uff0cYANGBEN",
        //   "SEX":"1",
        //   "BIRTHDAY":"1980-01-01 00:00:00"
        // }]

        let mr_no = od.data[0].MR_NO
        let cardInfo = that.props.cardInfo || {}
        let newCardInfo = Object.assign(cardInfo, { mr_no, ...datas })

        that.props.addCard(newCardInfo)
        that.queryBalance()
      }
      else{
        that.props.clearCard()
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 硬件通过串号获取患者是MR_NO
  queryHardwarePatientID(val){
    const that = this

    hardwareGetPatientID({
      qrCode: val,
    })
    .then(od => {
      if(od.status){
        let mr_no = od.data.MR_NO
        let cardInfo = that.props.cardInfo || {}
        let newCardInfo = Object.assign(cardInfo, { mr_no })
        that.props.addCard(newCardInfo)

        that.queryBalance(mr_no)
      }
      else{
        that.props.clearCard()
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 获取卡余额
  queryBalance(){
    let that = this
    let mr_no = this.props.cardInfo.mr_no

    getHisBalance({
      mr_no,
      pwd: '',
    })
    .then(od => {
      if(od.status){
        let balance = od.status ? od.data[0].BALANCE : 0,
            createCardInfo = Object.assign(that.props.cardInfo, { balance })
        that.props.addCard(createCardInfo)
        that.queryHisPatientInfo(mr_no)
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      that.props.clearCard()
    })
  }

  // 获取患者信息
  queryHisPatientInfo(mr_no){
    const that = this
    const { currentCard } = this.state

    getHisPatientInfo({
      mr_no
    })
    .then(od => {
      if(od.status){
        let list = od.data[0]
        let cardId = od.data[0].CERTI_NO
        const parentUrl = that.props.match.params.typeName;

        // 去除用户信息的空格
        for(var key in list){
          list[key] = list[key].trim()
        }

        if(parentUrl === 'hospitalizedInfo'
            || parentUrl === 'hcPatientInfo'
            || parentUrl === 'hpPatientInfo'
            || parentUrl === 'printHospitalizedECGReportInfo'){ // 住院回调
          that.queryHospitalization(cardId)
        }
        else{  // 门诊
          let card = Object.assign(that.props.cardInfo, {
            userInfo: list,
            currentCard
          })

          // 用户三要素脱敏
          const { NAME, CERTI_NO } = card.userInfo
          card.userInfo['NAME'] = NAME && that.userNameEncryption(NAME)
          card.userInfo['COMP_NAME'] = NAME

          // card.userInfo['PHONE'] = PHONE && that.phoneEncryption(PHONE)
          card.userInfo['CERTI_NO'] = NAME && that.idCardEncryption(CERTI_NO)
          card.userInfo['COMP_CERTI_NO'] = CERTI_NO

          localStorage.setItem('cardInfo', JSON.stringify(card))
          that.props.addCard(card)
          that.props.history.push(`/${that.props.match.params.typeName}`)
        }
      }
      else{
        that.props.clearCard()
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      that.props.clearCard()
    })
  }

  // 网络接口 - 获取用户信息
  queryNewworkCardId(val){
    const that = this
    const { currentCard } = this.state
    const { terminalCode } = this.props.base
    const businessRoute = this.props.match.params.typeName
    // medStepCode: 传值 010101 挂号    010105收费
    const medStepCode = (businessRoute === 'todaySelectDept') ? '010101' : '010105'

    getPersonInfoByQrCode({
      qrCode: val,
      medStepCode,
      terminalCode,
    })
    .then(od => {
      if(od.status){
        const { address='', idCode='', sex='', name='' } = od.data
        const parentUrl = that.props.match.params.typeName
        idCode.trim()

        if(idCode.length === ''){
          that.props.history.push({
            pathname : '/error',
            query : { messageTitle: '失败', messageContent: '获取身份证号码为空'}
          })
        }

        // 返回性别
        const sexVal = getGenderFromIDCard(idCode)
        // 返回出生年月日
        const birth = getBirthdayFromIDCard(idCode)

        // 判断来源是否建卡/无卡建卡
        if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){ 
          this.createCardInfo({
            ADDRESS: address,
            BIRTHDAY: birth,
            NAME: name,
            NUMBER: idCode,
            SEX: sexVal,
          })
          return ;
        }

        if(currentCard.resourcesMode === 2 && (parentUrl === 'hcPatientInfo' || parentUrl === 'hpPatientInfo')){  // 河北电子就诊卡，住院业务处理
          that.queryHospitalization(idCode)
        }
        else{
          that.queryPatientID({cardId: idCode})
        }
      }
      else{
        that.props.clearCard()
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
      that.props.clearCard()
    })
  }

  // 通过身份证获取住院号
  queryHospitalization(idCode){
    const that = this

    getPatientID({
      CERTI_NO: idCode,
    })
    .then(od => {
      if(od.status){
        let inNo = od.data[0].IN_NO
        that.queryDepositCost(inNo)
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 通过住院号获取患者信息
  queryDepositCost(inNo){
    const that = this
    const { currentCard } = this.state

    getDepositCost({
      in_no: inNo
    })
    .then(data => {
      if(data.status){
        let patientInfo = {},
            od = data.data[0]

        for(let key in od){
          patientInfo[key] = String.trim(od[key])
        }

        // 住院用户三要素脱敏
        let card = Object.assign(that.props.cardInfo, {
          userInfo: patientInfo,
          currentCard
        })
        const { NAME, CERTI_NO } = patientInfo
        card.userInfo['NAME'] = NAME && that.userNameEncryption(NAME)
        card.userInfo['COMP_NAME'] = NAME
        
        // card.userInfo['PHONE'] = PHONE && that.phoneEncryption(PHONE)
        card.userInfo['CERTI_NO'] = CERTI_NO && that.idCardEncryption(CERTI_NO)
        card.userInfo['COMP_CERTI_NO'] = CERTI_NO


        that.props.addInpatient({
          inpatientNo: inNo,
          patientInfo,
          // parentUrl: that.props.location.query.parentUrl || ''
        })

        // 跳到住院患者信息患者
        that.props.history.push(`/${that.props.match.params.typeName}`)
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 通过医保二维码获取患者信息
  queryYBPatinfo(qrCode){
    // getInsurEcCode({})
    const { base = {} } = this.props
    const { busCode } = this.state
    const {deviceId, hospitalInfo} = base
    let devId = parseInt(deviceId) < 10 ? `0${deviceId}` : deviceId

    const params = {
      businessType: `01${busCode}`,
      officeId: "zzj",
      officeName: "自助机",
      operatorId: "zzj",
      operatorName: "自助机",
      orgId: hospitalInfo.hospCode,
      ecQrCode: qrCode,
      deviceType: "SelfService",
      deviceId: devId
    }

    getInsurEcCodeApi(params)
    .then(res => {
      if(res.status){
        const parentUrl = this.props.match.params.typeName
        const { 
          IDNO='',
          USERNAME='',
          GENDER='',
          BIRTHDAY='',
         } = res.data

        // 判断来源是否建卡/无卡建卡
        if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){ 
          this.createCardInfo({
            ADDRESS: '',
            BIRTHDAY,
            NAME: USERNAME,
            NUMBER: IDNO,
            SEX: GENDER,
          })
          return ;
        }

        // 通过身份证id获取患者信息
        this.queryPatientID({cardId: IDNO})
      }
    })
    
    // const res = await ReadApi.getYBPatinfo(params);
    // console.log('电子医保卡res：', res)

    // if(res.status){
    //   const parentUrl = this.props.match.params.typeName
    //   const { certno='', gend='', psn_name='' } = res.data.baseinfo
  
    //   // 判断来源是否建卡/无卡建卡
    //   if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){ 
    //     this.createCardInfo({
    //       ADDRESS: '',
    //       BIRTHDAY: certno.substring(6,14),
    //       NAME: psn_name,
    //       NUMBER: certno,
    //       SEX: gend == 1 ? '男' : '女',
    //     })
    //     return ;
    //   }

    //   // 通过身份证id获取患者信息
    //   this.queryPatientID(res.data.baseinfo && res.data.baseinfo.certno)
    // }
  }

  // 通过本地硬件服务获取电子健康卡信息
  queryJKKPatient(cardNo){
    const that = this
    const { terminalCode } = this.props.base
    const businessRoute = this.props.match.params.typeName
    // medStepCode: 传值 010101 挂号    010105收费
    const medStepCode = (businessRoute === 'todaySelectDept') ? '010101' : '010105'

    localGetPersonInfoByQrCode({
      qrCode: cardNo,
      medStepCode,
      terminalCode,
    })
    .then(res => {
      if(res.status){
        const { ADDRESS='', IDCODE='', SEX='', NAME='' } = res.data
        const parentUrl = that.props.match.params.typeName
        IDCODE.trim()

        if(IDCODE.length === ''){
          that.props.history.push({
            pathname : '/error',
            query : { messageTitle: '失败', messageContent: '获取身份证号码为空'}
          })
        }

        // 判断来源是否建卡/无卡建卡
        if(parentUrl === 'confirmIdCard' || parentUrl === 'noCardConfirmIdCard'){ 
          this.createCardInfo({
            ADDRESS,
            BIRTHDAY: IDCODE.substring(6,14),
            NAME,
            NUMBER: IDCODE,
            SEX: SEX == 1 ? '男' : '女',
          })
          return ;
        }

        if(parentUrl === 'hcPatientInfo' || parentUrl === 'hpPatientInfo'){  // 河北电子就诊卡，住院业务处理
          that.queryHospitalization(IDCODE)
        }
        else{
          that.queryPatientID({cardId: IDCODE})
        }
      }
    })
    .catch(err => {
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 建档流程不一样，读卡后直接获取患者信息，不需要调用HisPatientInfo
  createCardInfo(paitInfo){
    const { currentCard } = this.state

    // 信息脱敏
    const { NAME, NUMBER, ID_TYPE='', CITIZENSHIPCODE='', PEOPLENATIONCODE='' } = paitInfo
    paitInfo['NAME'] = NAME && this.userNameEncryption(NAME)
    paitInfo['COMP_NAME'] = NAME
    
    paitInfo['CERTI_NO'] = NUMBER && this.idCardEncryption(NUMBER)
    paitInfo['COMP_CERTI_NO'] = NUMBER

    const newCardData = {
      userInfo: paitInfo,
      currentCard,
      idType: ID_TYPE,
      nationality: CITIZENSHIPCODE,
      folk: PEOPLENATIONCODE,
    }
    this.props.addCardData(newCardData)

    localStorage.setItem('cardInfo', JSON.stringify(newCardData))
    this.props.addCard(newCardData)
    this.props.history.push(`/${this.props.match.params.typeName}`)
  }

  // 身份证脱敏
  idCardEncryption(idCard){
    return idCard.replace(/^(.{3}).+(.{3})$/,"$1************$2")
  }

  // 手机号脱敏
  phoneEncryption(phone){
    return phone.replace(/^(.{3}).+(.{4})$/,"$1****$2")
  }

  // 姓名脱敏
  userNameEncryption(userName){
    let name = ''

    switch(userName.length){
      case '2':
        name = userName.replace(/^(.).+$/,"$1*")
        break

      case '3':
        name = userName.replace(/^(.).+(.)$/,"$1*$2")
        break

      case '4':
        name = userName.replace(/^(.).+(.)$/,"$1**$2")
        break

      default:
        name = userName.replace(/^(.).+(.)$/,"$1***$2")
        break
    }
    return name
  }

  // 退卡
  signOutCard(){
    let that = this;
    const { currentCard={} } = this.props.cardInfo || {};

    sendExitCard({
      RESOURSE_ID: currentCard.id
    })
    .then(od => {
      that.props.clearCardInfoStore();
      that.props.history.push('/')
    })
    .catch(err => {
      that.props.clearCardInfoStore()
      that.props.history.push({
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
    this.clearTimer()
    that.props.clearCardInfoStore();
  }

  clearTimer(callback){
    this.setState({
      isOut: true
    }, () => {
      window.clearInterval(time1)
      window.clearInterval(time2)
      window.clearTimeout(loopTime)
      if(callback){
        callback()
      }
    })
  }

  render() {
    const { cardName, currentCard={}, setp1, setp2, time1Num, time2Num, showInputCard }  = this.state

    return (
      <div>
        <Head quitTime={this.props.match.params.quitTime} />

        <div className="context-container clearfix">
          <div className="main-tip">
            <span className="pl-44">请把{ cardName }放到读取区</span>
            <span className="icon-rotate135">
            <i className="iconfont icon-shoushi" style={{fontSize: "40px"}}></i>
            </span>
            {
              currentCard.resourcesMode === 1 && <span className="time1">{time1Num}秒</span>
            }
          </div>

          {
            setp1 && <div className="read-tip1">
              <p>读取超时，请重新放置</p>
              <p>或<span style={{color: '#eb8b33', fontSize: '30px'}}>10秒</span>后自动退出</p>
            </div>
          }
          {
            setp2 && <div className="read-tip2">
              <p><span style={{color: '#eb8b33', fontSize: '40px'}}>友情提示：</span> <span style={{color: "#0e6eff", fontSize: "40px"}}>{time2Num}秒</span> </p>
              <p>您已退出，请取走身份证件！</p>
              <p style={{color: '#eb8b33', fontSize: '30px'}}>谢谢您的使用，再见！</p>
            </div>
          }
          
          {/* 是否需要扫码枪录入 */}
          {
            showInputCard.includes(currentCard.resourcesMode) ? 
              (
                <div style={{width: '500px', margin: '10px auto 0'}}>
                  <input type="text"
                    ref={this.inputRef}
                    onKeyUp={(evt) => this.bindKeyBoard(evt)}
                    style={{width: '500px', height: '50px', marginTop: '20px', border: '1px #ccc solid', fontSize: '31px'}} 
                  />
                </div>
              ) : ''
          }

          <div className="read-card">
            { this.showCard() }
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
                  style={{ "backgroundColor": "#bfdfe9", color: "#8D2829", border: "1px #3976A9 solid", "float": "right"}}>
            退 出
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    base: state.base,
    inpatient: state.inpatient,
    cardInfo: state.cardInfo
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addBase: (data) => {
      dispatch(baseAction.addBase(data))
    },
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data))
    },
    addCardData: (data) => {
      dispatch(Action.addCardData(data));
    },
    addInpatient: (data) => {
      dispatch(inpatientAction.addInpatient(data))
    },
    clearCard: () => {
      dispatch(cardInfoAction.clearCard(null))
    },
    clearCardInfoStore: (data) => {
      dispatch(cardInfoAction.clearCard());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadCard)
