import React, { Component } from 'react'
import Head from '../head/'
import { Button, Icon, notification } from 'antd';
import { connect } from 'react-redux';
import { getInvoiceFileOne, printeinvoice, finishPrintInvoice } from '@/utils/request'
import businessQuitTime from '../../utils/businessQuitTime'
import './print.scss'

let seccessNum = 0

class pubPrint extends Component {
  constructor(props){
    super(props);
    this.state = {
      printContext: '准备打印...',
      printLen: 0,
      printSerial: '',
      invoiceFiles: [],
    }
  }

  goHomePage(){
    this.props.history.push('/')
  }

  componentWillUnmount() {
    seccessNum = 0
  }

  componentDidMount(){
    const that = this
    const { print, base } = this.props
    seccessNum = 0;

    switch(print.businessType){
      case 1:
        const invoiceFiles = print.printData
        const printLen = invoiceFiles.length
        const einvoicePrint = base.einvoicePrint || ''

        // 配置更改，如果配置等于1只打一次，如果大于1按接口返回数组有几次就打印几次
        const printTil = einvoicePrint === 1 ? einvoicePrint : printLen

        if(printLen === 0){
          notification.error({
            message: 'QueryInvoiceFile未获取到电子发票信息',
            description: '错误',
            duration: 10,
          });
          return false
        }
        this.setState({
          printContext: `总共有${printTil}份文件正在打印...`,
          printSerial: invoiceFiles[0]['业务流水号'],
          printLen,
          invoiceFiles,
        }, function(){
          that.queryInvoiceFileOne(0)
        });

        break

      default: break
    }
  }

  // 获取电子发票打印base64图片
  queryInvoiceFileOne(num){
    const that = this
    const { invoiceFiles } = this.state

    getInvoiceFileOne({
      "业务流水号": invoiceFiles[num]['业务流水号'],
      "文件名称": invoiceFiles[num]['文件名称'],
    })
    .then(od => {
      if(od.status){
        let file = od.data[0];
        that.sendPrinteInvoice(file)
      }
      else{
        seccessNum = 0
        notification.error({
          message: od.message,
          description: '错误',
          duration: 10,
        });
      }
    })
    .catch(err => {
      seccessNum = 0
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 调硬件打印
  sendPrinteInvoice(file){
    const that = this
    const { printLen } = this.state
    const { base } = this.props
    const einvoicePrint = base.einvoicePrint

    printeinvoice({
      einvoicefilename: file['文件名称'],
      einvoicefile: file['文件'],
    })
    .then(od => {
      if(od.status){
        console.log('当前打印次数 seccessNum', seccessNum)
        seccessNum++;

        // 如果配置1就打印1次，如果配置大于1
        if(einvoicePrint === 1){
          that.sendFinishPrintInvoice()

          // if(seccessNum == printLen || einvoicePrint == seccessNum){
          //   that.sendFinishPrintInvoice()
          // }
          // else{
          //   that.queryInvoiceFileOne(seccessNum)
          // }
        }
        else{
          if(seccessNum == printLen){
            that.sendFinishPrintInvoice()
          }
          else{
            that.queryInvoiceFileOne(seccessNum)
          }
        }        
      }
      else{
        seccessNum = 0
        notification.error({
          message: od.message,
          description: '错误',
          duration: 10 ,
        });
      }
    })
    .catch(err => {
      seccessNum = 0
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }

  // 打印成功后调用his状态
  sendFinishPrintInvoice(){
    const that = this
    const { printSerial } = this.state
    seccessNum = 0
    console.log('Finis 打印成功')

    finishPrintInvoice({
      "业务流水号": printSerial,
    })
    .then(od => {
      if(od.status){
        that.setState({
          printContext: od.message
        })
      }
      else{
        that.setState({
          printContext: '打印失败'
        })
        notification.error({
          message: od.message,
          description: '错误',
          duration: 10,
        });
      }
    })
    .catch(err => {
      that.props.history.push({ 
        pathname : '/error',
        query : { messageTitle: '失败', messageContent: err.toString()}
      })
    })
  }


  render() {
    const { printContext } = this.state

    return (
      <div>
        <Head quitTime={businessQuitTime.print.payPrint} />

        <div className="context-container clearfix">
          <div className="status-box">
            <div className="print-ico">
              <i className="icon iconfont icon--tiaomadayin"></i>
            </div>
            <div className="print-text">
              {printContext}
            </div>
          </div>
        </div>

        <div className="footer">
          <Button type="primary" size="large" onClick={this.goHomePage.bind(this)}
            style={{ "backgroundColor": "#f76e6e", border: "1px #f76e6e solid", "float": "right"}}>
            <Icon type="poweroff" theme="outlined" />退 出</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { base, print } = state
  return {
    base,
    print, 
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addCard: (data) => {
      dispatch(cardInfoAction.addCard(data));
    },
    addRecharge: (data) => {
      dispatch(rechargeAction.addRecharge(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(pubPrint)