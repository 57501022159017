/*
 * 全局配置项
 */
import moment from 'moment'

const IsScreen = 'min'

export default {
  // 是否启用mock
  mockEnabled: false,

  // 测试打印日志
  isShowTestLog: true,

  // 是否播放声音
  isAudio: false,

  modalTop: {
    top: IsScreen === 'max' ? '62%' : '30%'     // 大屏 60%，小屏 30%
  },

  upDateTime: '2024.12.05 11:30',

  /********************** 河北测试环境 ***********************/
  // 内网
  // 硬件服务地址
  hardwareUrl: 'http://127.0.0.1:2018',
  // his服务地址
  hisUrl: 'http://his.ssm.dev.yyt.wb.io',
  // 后台、支付服务地址
  adminUrl: 'http://adm.ssm.dev.yyt.wb.io/open/sstk',
  // 接口日志地址
  loggerUrl: 'http://log.ssm.dev.yyt.wb.io/open/logger',
  // 挂号、取号接口
  registrationUrl: 'http://hors.ssm.dev.yyt.wb.io/open/hors',
  // 查询API
  queryAPIUrl: 'http://query.ssm.dev.yyt.wb.io',
  // api
  // apiUrl: 'http://api.agzyy.hosp.dev.yyt.wb.io',
  // apiUrl: 'http://10.8.32.43:3301',
  apiUrl: 'http://127.0.0.1:3301',
  // empi
  empiUrl: 'http://clinic.api.wb.io',

  // // 外网
  // // 硬件服务地址
  // hardwareUrl: 'https://hd.ssm.dev.tsingcreate.net',
  // // his服务地址
  // hisUrl: 'https://his.ssm.dev.tsingcreate.net',
  // // 后台、支付服务地址
  // adminUrl: 'https://adm.ssm.dev.tsingcreate.net/open/sstk',
  // // 接口日志地址
  // loggerUrl: 'https://log.ssm.dev.tsingcreate.net/open/logger',
  // // 挂号、取号接口
  // registrationUrl: 'https://hors.ssm.dev.tsingcreate.net/open/hors',
  // // 查询API
  // queryAPIUrl: 'https://query.ssm.dev.tsingcreate.net',
  // // api
  // apiUrl: 'https://api.agzyy.hosp.dev.tsingcreate.net',
  // // empi
  // empiUrl: 'http://clinic.api.wb.io',

  /************************ 开发环境 *************************/
  // 自助机测试
  // hisUrl: 'https://his.ssm.dev.tsingcreate.net',
  // hardwareUrl: 'https://hd.ssm.dev.tsingcreate.net',
  // // adminUrl: 'https://adm.ssm.dev.tsingcreate.net/open/sstk',
  // adminUrl: 'http://adm.ssm.dev.yyt.wb.io/open/sstk',
  // loggerUrl: 'http://log.ssm.dev.tsingcreate.net/open/logger',
  // registrationUrl: 'https://hors.ssm.dev.tsingcreate.net/open/hors',   // 小程序测试库
  // queryAPIUrl: 'https://query.ssm.dev.tsingcreate.net',
  // // apiUrl: 'https://api.agzyy.hosp.dev.tsingcreate.net',
  // apiUrl: 'http://10.8.32.43:3301',


  /************************* 生产环境 ************************/
  // 卫康
  // hisUrl: 'http://his.weikang.hospital.open.ehis.cn:5555',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://sstk.weikang.hospital.open.ehis.cn/open/sstk',
  // loggerUrl: 'http://sstk.weikang.hospital.open.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.weikang.hospital.open.ehis.cn/open/hors',

  // 魏县妇幼保健院（小屏）
  // hisUrl: 'http://his.wxfy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.wxfy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.wxfy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.wxfy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.wxfy.hosp.ehis.cn',

  // 魏县人民医院（小屏）
  // hisUrl: 'http://his.wxrmyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.wxrmyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.wxrmyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.wxrmyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.wxrmyy.hosp.ehis.cn',
  // apiUrl: 'http://api.agzyy.hosp.open.ehis.cn/',

  // 安国中医院（小屏）
  // hisUrl: 'http://his.agzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.agzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.agzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.agzyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.agzyy.hosp.ehis.cn',
  // apiUrl: 'http://192.168.123.100:3301',

  // 鸡泽中医院（小屏）
  // hisUrl: 'http://his.jzzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.jzzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.jzzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.jzzyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.jzzyy.hosp.ehis.cn',

  // 鸡泽县医院（小屏）
  // hisUrl: 'http://his.jzxyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.jzxyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.jzxyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.jzxyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.jzxyy.hosp.ehis.cn',
  // apiUrl: 'http://123.123.123.4:3301',

  // 容城中医院（小屏）
  // hisUrl: 'http://his.rczyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.rczyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.rczyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.rczyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.rczyy.hosp.ehis.cn',

  // 晋州市中医院（小、大屏）
  // hisUrl: 'http://his.ggzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.ggzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.ggzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.ggzyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.ggzyy.hosp.ehis.cn',
  // apiUrl: 'http://invoice.ggzyy.hosp.ehis.cn:3301',

  // 故城县医院（大屏）
  // hisUrl: 'http://his.gcxyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.gcxyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.gcxyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.gcxyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.gcxyy.hosp.ehis.cn',

  // 邱县中医院（小屏）
  // hisUrl: 'http://his.qxzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.qxzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.qxzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.qxzyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://his.qxzyy.hosp.ehis.cn:3310',
  // queryAPIUrl: 'http://query.ssm.qxzyy.hosp.ehis.cn',

  // 邱县人民医院（小屏）
  // hisUrl: 'http://his.qxzxyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.qxzxyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.qxzxyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.qxzxyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://172.16.1.200:3310',
  // queryAPIUrl: 'http://query.ssm.qxzxyy.hosp.ehis.cn',

  // 赤城中医院（小屏）
  // hisUrl: 'http://his.cczyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.cczyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.cczyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.cczyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.cczyy.hosp.ehis.cn',
  // apiUrl: 'http://120.120.120.1:3301',

  // 顺平县医院（小屏）
  // hisUrl: 'http://his.spxyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.spxyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.spxyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.spxyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.spxyy.hosp.ehis.cn',
  // apiUrl: 'http://192.168.0.95:3301',

  // 张家口市沙岭子医院（小屏）
  // hisUrl: 'http://his.slzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.slzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.slzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.slzyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.slzyy.hosp.ehis.cn',

  // 三河妇幼保健院（小屏）
  // hisUrl: 'http://his.shfy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.shfy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.shfy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.shfy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.shfy.hosp.ehis.cn',
  // apiUrl: 'http://his.shfy.hosp.ehis.cn:3301',

  // 涿鹿县医院（小屏）
  // hisUrl: 'http://his.zlxyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.zlxyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.zlxyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.zlxyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.zlxyy.hosp.ehis.cn',

  // 文安县中医院（小屏）
  // hisUrl: 'http://his.wazyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.wazyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.wazyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.wazyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://his.wazyy.hosp.ehis.cn:3301',
  // queryAPIUrl: 'http://query.ssm.wazyy.hosp.ehis.cn',

  // 磁县人民医院（大屏）
  // hisUrl: 'http://his.cxrmyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.cxrmyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.cxrmyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.cxrmyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.cxrmyy.hosp.ehis.cn',
  // apiUrl: 'http://10.0.0.54:3301',
  // empiUrl: 'http://clinic.api.hosp.cn',

  // 曲阳第二中心医院（小屏）
  // hisUrl: 'http://his.qydezxyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.qydezxyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.qydezxyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.qydezxyy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.qydezxyy.hosp.ehis.cn',

  // 曲阳中医院（小屏）
  // hisUrl: 'http://his.qyzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.qyzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.qyzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.qyzyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://125.125.125.100:3301',
  // queryAPIUrl: 'http://query.ssm.qyzyy.hosp.ehis.cn',

  // 晋州康华医院（大小屏都有）
  // hisUrl: 'http://his.jzkhyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.jzkhyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.jzkhyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.jzkhyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://120.120.120.245:3301',
  // queryAPIUrl: 'http://query.ssm.jzkhyy.hosp.ehis.cn',

  // 行唐中医医院（小屏）
  // hisUrl: 'http://his.xtzyyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.xtzyyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.xtzyyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.xtzyyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://his.xtzyyy.hosp.ehis.cn:3301',
  // queryAPIUrl: 'http://query.ssm.xtzyyy.hosp.ehis.cn',

  // 怀安普阳医院（大屏）
  // hisUrl: 'http://his.harjyy1.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.harjyy1.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.harjyy1.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.harjyy1.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.harjyy1.hosp.ehis.cn',

  // 广平妇幼（小屏）
  // hisUrl: 'http://his.gpfy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.gpfy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.gpfy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.gpfy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.gpfy.hosp.ehis.cn',

  // 安国妇幼（小屏）
  // hisUrl: 'http://his.agfy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.agfy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.agfy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.agfy.hosp.ehis.cn/open/hors',
  // queryAPIUrl: 'http://query.ssm.agfy.hosp.ehis.cn',

  // 平山县中医院（大屏）
  // hisUrl: 'http://his.pszyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.pszyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.pszyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.pszyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://192.168.0.6:3301',
  // queryAPIUrl: 'http://query.ssm.pszyy.hosp.ehis.cn',

  // 张北妇幼医院（大屏）
  // hisUrl: 'http://his.zbfy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.zbfy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.zbfy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.zbfy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://api.zbfy.hosp.open.ehis.cn',
  // queryAPIUrl: 'http://query.ssm.zbfy.hosp.ehis.cn',

  // 沽源县中医院（大屏）
  // hisUrl: 'http://his.gyzyy.hosp.ehis.cn:2019',
  // hardwareUrl: 'http://127.0.0.1:2018',
  // adminUrl: 'http://adm.ssm.gyzyy.hosp.ehis.cn/open/sstk',
  // loggerUrl: 'http://log.ssm.gyzyy.hosp.ehis.cn/open/logger',
  // registrationUrl: 'http://hors.ssm.gyzyy.hosp.ehis.cn/open/hors',
  // apiUrl: 'http://invoice.gyzyy.hosp.ehis.cn:3301',
  // queryAPIUrl: 'http://query.ssm.gyzyy.hosp.ehis.cn',
}